import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import {IntlProvider} from "react-intl";
import moment from 'moment';
import 'moment/locale/de';
import German from './lang/de.json';

import GummiesTemplate from "./templates/GummiesTemplate";
import ChakraTheme from "./templates/Chakra";
import Home from "./pages/home/Home";
import Step2 from "./pages/step2/Step2";
import Step1 from "./pages/step1/Step1";
import Loader from "./pages/loader/Loader";
import Complete from "./pages/complete/Complete";

function App() {
    let locale = 'de';
    let momentLocale = 'de';
    let messages = German;

    document.title = 'Slimming Gummies - ' + messages.title;

    moment.locale(momentLocale);

    const config = {
        locale: locale
    }

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <GummiesTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2 config={config}/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                            <Route path='/complete' element={
                                <Complete config={config}/>
                            }/>
                        </Routes>
                    </GummiesTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
