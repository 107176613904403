import {extendTheme} from "@chakra-ui/react"
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

const ChakraTheme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "#e5e4e0"
            }
        }
    },
    fontWeights: {
        hairline: 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 400,
        semibold: 400,
        bold: 500,
        extrabold: 400,
        black: 400,
    },
    lineHeights: {
        normal: 1.5,
    },
    fonts: {
        heading: "Poppins",
        body: "Poppins"
    },
    colors: {
        brand: '#e74b55'
    },
})

export default ChakraTheme;
