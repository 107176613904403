import {Box, Flex, Center, Container, Heading, HStack, Spacer, Text} from "@chakra-ui/react";

import './Home.scss';
import maleImg from '../../assets/home/male-img.png';
import maleIcon from '../../assets/home/male-icon.svg';
import femaleIcon from '../../assets/home/female-icon.svg';
import femaleImg from '../../assets/home/female-img.png';
import {useNavigate} from "react-router-dom";
import useStore from "../../storage/DataStore";
import {FormattedMessage} from "react-intl";
import React from "react";

function Home() {
    let navigate = useNavigate();

    const nextStep = function (gender) {
        useStore.setState({
            gender: gender
        });

        navigate('/step1', {replace: true});
    }

    return (
        <Container p={1} mb={0} maxW="container.xl">
            <Flex className={'header'} borderRadius={['0.5rem 0.5rem 0 0']}>
                <Spacer/>
                <Box p={[10, 100]} pt={[3, 170]} pb={[260, 170]} width={['100%', '50%']}>
                    <Heading fontSize={{base: "22px", lg: "45px"}} color={'brand'} textAlign={'center'} lineHeight={[1,1.3]}>
                        <FormattedMessage id="home.header"/>
                    </Heading>
                </Box>
                <Spacer/>
            </Flex>
            <Box
                background={"white"}
                pt={[1,5]}
                pb={5}
                borderRadius={['0 0 0.5rem 0.5rem']}
            >
                <Flex mb={[5, 0]}>
                    <Spacer />
                    <Box textAlign={'center'} width={['100%','70%']} p={2}>
                        <Heading fontSize={{base: "18px", lg: "34px"}} color={'brand'} textAlign={'center'}>
                            <FormattedMessage id="home.text1"/>
                        </Heading>
                        <Text mt={[3, 5]} fontSize={{base: "14px", lg: "28px"}}>
                            <FormattedMessage id="home.text2"/>
                        </Text>
                    </Box>
                    <Spacer />
                </Flex>
                <HStack>
                    <Box w={'25%'} className={'hidden-mobile'}>
                        <img src={maleImg} alt=""/>
                    </Box>
                    <Box w={['100%', '100%', '50%']}>
                        <Box width={'100%'} textAlign={'center'} mb={5}>
                            <Heading as="h3" fontWeight={700} size="lg">
                                <FormattedMessage id="home.selectGender"/>
                            </Heading>
                        </Box>
                        <Center>
                            <HStack>
                                <Box pr={[2, 5]} width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <Box onClick={() => nextStep('male')} className={'circle circle-male'}>
                                        <img src={maleIcon} alt="male"/>
                                        <p className={'label'}><FormattedMessage id="home.selectGender.male"/></p>
                                    </Box>
                                </Box>
                                <Box pl={[2, 5]} width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <Box onClick={() => nextStep('female')} className={'circle circle-female'}>
                                        <img src={femaleIcon} alt="female"/>
                                        <p className={'label'}><FormattedMessage id="home.selectGender.female"/></p>
                                    </Box>
                                </Box>
                            </HStack>
                        </Center>
                    </Box>
                    <Box w={'25%'} className={'hidden-mobile'}>
                        <img src={femaleImg} alt=""/>
                    </Box>
                </HStack>
            </Box>
        </Container>
    );
}

export default Home;
